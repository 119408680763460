import { useParams } from 'react-router-dom'
import { useAPI } from '../hooks'
import DataList from '../components/phishingData/DataList'
import React from 'react'

const NewPhishing = () => {
  const { channel } = useParams()
  const { newPhishing } = useAPI()
  const { data } = newPhishing(channel)
  console.log('newPhishing', data)
  return (
    <>
      {
        (data != null)
          ? data.pages.map((group, index) =>
            <section key={index}>{
            (group != null)
              ? <DataList type='new' data={group} />
              : null
          }
            </section>
          )
          : <div />
      }
    </>
  )
}
export default NewPhishing
