/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { heading2 } from '../../styles/components/textStyle'
import { ContentsLayout } from '../../layout'
import { decode } from 'js-base64'
import { serverURL } from '../../service/const'
import { useAppFunc, useShare } from 'hooks'
import {colors} from "../../styles";
import kakaoIcon from "assets/svgs/ico_logo_kakao_24.svg"
import whiteIcon from "../../assets/icon/ico_logo_white_24.png"
const floatingBtn = css`
  border: none;
  width: 100%;
  height: 55px;
  font-size: 16px;
  font-weight: 700;
  color: ${colors.back01};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  img {
    width: 24px;
  }
`

interface DataDetailProps extends PhishingDataDetail {pCode: string | undefined}

const DataDetail = ({
  Idx,
  Title,
  ContentsText,
  Contents,
  pCode,
  serviceImg,
  images
}: DataDetailProps) => {
  const { share } = useAppFunc()
  const { kakaoShare } = useShare()
  const isLive = window.location.href.includes('https://webapp.antiscam.co.kr')
  const selectEnv = () => isLive ? serverURL.webappLive : serverURL.webappDev
  const mobileUrl = `${selectEnv()}/PhishingDataDetail${!pCode ? '/antiscamshare' : `/${pCode}`}/${Idx}`
  const handlePrevent = () => {
    window.location.href = `${serverURL.partner}/serviceadd/${!pCode ? 'antiscamshare' : `${pCode}`}`
  }
  return (
    <ContentsLayout>
      <section css={css`display: flex;
        flex-direction: column;
        gap: 20px;
        img {
          width: 100%;
        }
        padding-bottom: 60px;
      `}
      >
        <section>
          <h2 css={css`${heading2}; margin:10px auto 0 auto;`}>{decode(Title)}</h2>
        </section>
        {
          images.length > 1
            ? images.map((i, index) => <img key={index} src={`${serverURL.img}/${i}`} alt={`${i}`} />)
            : <section css={css`width: 100%;`} dangerouslySetInnerHTML={{ __html: decode(Contents) }} />
        }
        <section css={css`
          display: flex;
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
        `}
        >
          {pCode && <button css={css`${floatingBtn}; background: ${colors.main01}; color: white;`} onClick={handlePrevent}>
            <img css={css`width: 24px;`} src={whiteIcon} alt={'메인 아이콘'} />
            금융사기 예방하기
          </button>}
          <button
            css={css`${floatingBtn}; background: #fff500;`} onClick={() => !pCode
              ? share(mobileUrl)
              : kakaoShare({
                title: `${decode(Title)}`,
                text: `${decode(ContentsText)}`,
                url: mobileUrl,
                imgUrl: `${serverURL.img}/serviceimage/${serviceImg}`
              })}
          >
            <img css={css`width: 24px;`} src={kakaoIcon} alt={'카카오 아이콘'} />
            카톡으로 공유
          </button>
        </section>
      </section>
    </ContentsLayout>
  )
}
export default DataDetail