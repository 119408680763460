/** @jsxImportSource @emotion/react */
import {ContentsLayout} from '../layout'
import {css} from '@emotion/react'
import {useState} from 'react'
import {banks} from '../service/const'
import {colors} from '../styles'
import {body10} from '../styles/components/textStyle'
import CallBtn from '../components/common/btn/CallBtn'

const SelectBank = () => {
  const [selectedBank, setSelectedBank] = useState(0)
  return (
    <div
    css={css`
      height: 100vh;
      overflow: hidden;
    `}
    >
      <ContentsLayout>
      <section css={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px 12px;
            margin: 13px 0;
            max-height: calc(100vh - 140px);
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            ::-webkit-scrollbar {
              width: 5px;
            }
            ::-webkit-scrollbar-thumb {
              background-color: #ccc;
            }
            ::-webkit-scrollbar-thumb:window-inactive {
              background-color: #ccc;
            }
          `}
        >
          {banks.map((bank, index) =>
            <button
              css={
                css`${body10};
                  height: 32px;
                  display: flex;
                  background: ${selectedBank === index ? colors.back06 : 'none'};
                  border: none;
                  border-radius: 50px;
                  &:hover {
                    background-color: ${colors.back06}
                `
              } key={index} onClick={() => setSelectedBank(index)}
            >
              <img css={css`margin: auto 0;
                width: 20px;
                height: 20px;`} src={bank.imgPath} alt={bank.name}/>
              <div css={css`margin: auto auto auto 12px; color:#2A292E;`}>{bank.name}</div>
            </button>
          )}
        </section>
      </ContentsLayout>
      <section css={css`
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 120px;
        border-top: 1px solid ${colors.back06};
        background: white;
      `}
      >
        <ContentsLayout>
          <article css={css`
            display: flex;
            width: 100%;
            margin: 20px auto;
            gap: 10px;
          `}
          >
            <img css={css`width: 20px;
              height: 20px;`} src={banks[selectedBank].imgPath} alt={banks[selectedBank].name}/>
            <div css={css`display: grid;
              width: 100%;
              gap: 10px;`}>
              <div css={body10}>{banks[selectedBank].name}</div>
              <div css={css`display: flex;
                gap: 10px;`}>
                {banks[selectedBank].tel.map(t =>
                  <div key={t} css={css`display: flex;
                    gap: 10px;`}>
                    <CallBtn tel={t}/>
                    <div css={css`margin: auto;`}>{t}</div>
                  </div>
                )}
              </div>
            </div>
          </article>
        </ContentsLayout>
      </section>
    </div>

  )
}
export default SelectBank