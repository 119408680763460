/** @jsxImportSource @emotion/react */
import { useAPI } from 'hooks'
import { css } from '@emotion/react'
import { body08, body09, description2, description5, heading2 } from '../../styles/components/textStyle'
import dayjs from 'dayjs'
import { useState } from 'react'
import { decode } from 'js-base64'
import { colors } from '../../styles'
import { dayFormatReplace } from '../../utils/replace'

const NoticeList = () => {
  const { notice } = useAPI()
  const { data } = notice()
  const [active, setActive] = useState(-1)
  const detail = (index: number, target: EventTarget & HTMLElement) => {
    if (active === index) {
      setActive(-1)
    } else {
      setActive(index)
      console.log('target ', target)
      console.log('element ', target.scrollTop - 200)
      setTimeout(() => {
        target.scrollIntoView({ block: 'start' })
      }, 200)
    }
  }
  return (
    <section css={css`
  `}
    >
      <div css={css`
      ${heading2};
      padding: 20px 0;
      width: 100%;
      position: fixed;
      background: #fff;`}
      >공지사항
      </div>
      <div css={css`height: 60px`} />
      <div css={css`overflow-y:hidden`}>
        {(data != null)
          ? data.map((item) =>
            <article
              key={item.Idx} css={css`margin: 20px 0;
            display: grid;
            gap: 6px;`} onClick={(event) => detail(item.Idx, event.currentTarget)}
            >
              <div css={css`${description5};
              color: ${colors.back04};`}
              >
                {dayFormatReplace(dayjs(item.RegDT).locale('ko').format('YYYY년 M월 D일(ddd) HH:mm'))}
              </div>
              <div css={css`${body08}`}>{decode(item.Title)}</div>
              <article css={css`
              ${description2};
              padding: 16px;
              background: ${colors.main06};
              color: ${colors.main03};
              border-radius: 10px;
              ${active === item.Idx ? 'display: box;' : 'display: none;'};
            `}
              >
                <div
                  css={css`${body09};

                img {
                  width: 100%;
                }`} dangerouslySetInnerHTML={{ __html: decode(item.Contents) }}
                />
              </article>
            </article>)
          : <div />}
      </div>
    </section>
  )
}
export default NoticeList
