/** @jsxImportSource @emotion/react */
import { tabDescription } from '../../styles/components/antiscamTabStyle'
import { buttonType6 } from 'styles/components/btnStyle'
import { css } from '@emotion/react'
import { colors } from '../../styles'
import TabArticle from '../common/tab/TabArticle'
import {body04, body10, body11, body12, description2, description3} from '../../styles/components/textStyle'
import CallBtn from '../common/btn/CallBtn'
import { useAppFunc, useScroll } from '../../hooks'

const DamageTabItem1 = () => {
  const { bank } = useAppFunc()
  const { scrollTop } = useScroll()
  scrollTop()
  return (
    <section css={body11}>
      <section css={description2}>
        <p css={css`margin: 20px 0 14px 0;`}>보이스피싱 범죄 계좌에 입금을 하셨다면 범죄자가 피해금을 인출해 도주하기 전 지급정지가 필요합니다.</p>
        <p>지급정지는 피해금이 인출된 은행 또는 피해금이 입금된 은행 모두 신고 가능합니다.</p>
        <p css={css`color: ${colors.red00}`}>※ 최우선적으로 지급정지가 필요합니다.</p>
      </section>
      <section>
        <h2 css={css`${body04}; margin: 30px auto 20px`}>금융감독원 또는 관련 은행에 지급정지 신청</h2>
        <TabArticle>
          <div css={css`margin: auto auto auto 0;`}>
            <div css={css`${description2}; font-weight: 700; margin-bottom: 3px` }>금융감독원</div>
            <div css={css`${description3}; color: #808080;`}>09:00~18:00 주말 및 공휴일 휴무</div>
          </div>
          <CallBtn tel='1332' />
        </TabArticle>
        <TabArticle>
          <div css={css`${body10}; margin: auto auto auto 0;`}>24시간 연중무휴 · 은행 콜센터</div>
          <button css={buttonType6} onClick={() => bank()}>은행 선택하기</button>
        </TabArticle>
      </section>
      <section>
        <h2 css={css`${body04}; margin: 30px auto 20px`}>지급정지 요청 흐름</h2>
        <img css={css`width: 100%`} src='/images/img_report_content1.png' alt='지금정지 요청 흐름도' />
        <section css={body12}>
          <p>계좌지급정지를 신청했다면,  돈이 입금된 계좌는 인출정지 상태가 됩니다.</p>
          <p>계좌지급정지 신청 후<span css={css`color: ${colors.red00}`}>영업일 3일 이내에 경찰서에서 발급받은 서류를 은행에 제출</span>해야합니다. 이후
            <span css={css`text-decoration: underline`}>14일 간 추가 제출 기한</span>이 주어지며, 이 기간을 넘기면 피해구제 신청을 할 수 없습니다.
          </p>
          <p css={tabDescription}>※ 지급정지 종료 및 피해구제신청과 관련된 자세한 사항은 유선지급정지를 신청한 금융회사로 문의해 주시기 바랍니다.</p>
          <p css={tabDescription}>출처: 금융감독원</p>
        </section>
      </section>
    </section>
  )
}
export default DamageTabItem1
