/** @jsxImportSource @emotion/react */
import { buttonCall } from '../../../styles/components/btnStyle'
import { css } from '@emotion/react'
import callIcon from 'assets/icon/button_call.png'

interface CallBtnProps {
  tel: string
}

const CallBtn = ({ tel }: CallBtnProps) => {
  const handleClick = () => window.location.href = `tel:${tel}`
  return (
    <button css={css`${buttonCall}; padding:0;`} onClick={handleClick}>
      <img css={css`margin: auto; width: 34px; height: 34px;`} src={callIcon} alt='phone icon' />
    </button>
  )
}
export default CallBtn
