/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { serverURL } from '../../service/const'
import { body11 } from '../../styles/components/textStyle'
import { decode } from 'js-base64'
import { ItemProps } from './NewItem'
import { useAppFunc } from '../../hooks'
import { ReactComponent as NewIcon } from 'assets/svgs/new_icon.svg';

const MainItem = ({ Idx, CardThumbNail, Title, isNew = false }: ItemProps) => {
  const { detail } = useAppFunc()
  return (
    <article
      css={css`
      margin: auto;
      flex-direction: column; 
      gap: 10px;
      `}
      onClick={() => detail(Idx)}
    >
      {isNew && <NewIcon css={css`
        position: relative;
        top: 18px;
        right: 10px;
      `} />}
      <img
        css={css`
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: gray;
      `} src={`${serverURL.img}/${CardThumbNail}`} alt='new_thumbnail'
      />
      <div css={css`
        ${body11};
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      `}
      >{decode(Title)}
      </div>
    </article>
  )
}
export default MainItem
