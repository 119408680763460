import { useParams } from 'react-router-dom'
import { useAPI } from '../hooks'
import DataList from '../components/phishingData/DataList'

/*
* 주요피싱유형
* */
const MainPhishing = () => {
  const { channel } = useParams()
  const { mainPhishing } = useAPI()
  const { data } = mainPhishing(channel)
  console.log('mainkPhishing', data)
  return (
    <>
      {
        (data != null)
          ? data.pages.map((group, index) =>
            <section key={index}>{
            (group != null)
              ? <DataList type='main' data={group} />
              : null
          }
            </section>
          )
          : <div />
      }
    </>
  )
}
export default MainPhishing
