interface ShareProps {
  title: string
  text: string
  url: string
}
interface KakaoShareProps extends ShareProps {
  imgUrl: string
}
const useShare = () => {
  const kakaoShare = ({ title, text, url, imgUrl }: KakaoShareProps) => {
    window.Kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title,
        description: text,
        imageUrl: imgUrl,
        link: {
          mobileWebUrl: url
        }
      },
      buttons: [{
          title: "자세히 보기",
          link: {
            mobileWebUrl: url,
          },
        },
      ],
      installTalk: true,
    })
  }
  const handleShare = ({ title, text, url }: ShareProps) => {
    if (navigator.share) {
      navigator.share({
        title,
        text,
        url
      }).then(() => console.log('공유성공'))
        .catch((error) => console.error(error))
    } else {
      alert('공유하기가 지원되지 않는 환경입니다.')
    }
  }
  return {
    handleShare,
    kakaoShare
  }
}
export default useShare
