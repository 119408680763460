const kakaoShareReplace = (data: string) => data
  .replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/ig, '')
const ttsContReplace = (data: string) => kakaoShareReplace(data)
  // eslint-disable-next-line
  .replace(/[\{\}\[\]\/?;:|\)*~`!^\-_+<>@\#$&\\=\(\'\"]/gi, "")
const dayFormatReplace = (date: string) => {
  return date
    .replace('Mon', '월')
    .replace('Tue', '화')
    .replace('Wed', '수')
    .replace('Thu', '목')
    .replace('Fri', '금')
    .replace('Sat', '토')
    .replace('Sun', '일')
}
export {
  kakaoShareReplace,
  ttsContReplace,
  dayFormatReplace
}
