import { css } from '@emotion/react'

export const heading1 = css`
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
  text-align: left;
`
export const heading2 = css`
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
`
export const heading3 = css`
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  text-align: left;
`
export const body01 = css`
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
`
export const body02 = css`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
`
export const body03 = css`
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
`
export const body04 = css`
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -.03em;
`
export const body05 = css`
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
`
export const body06 = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
`
export const body07 = css`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`
export const body08 = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`
export const body09 = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`
export const body10 = css`
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -.04em;
`
export const body11 = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`
export const body12 = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
`
export const description1 = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`
export const description2 = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
`
export const description3 = css`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
`
export const description4 = css`
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
`
export const description5 = css`
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
`
