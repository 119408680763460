import NoticeList from '../components/notice/NoticeList'
import { ContentsLayout } from '../layout'

const Notices = () => {
  return (
    <ContentsLayout>
      <NoticeList />
    </ContentsLayout>
  )
}
export default Notices
