import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  CardContents,
  CardContentsDetail,
  CaseSharing,
  CaseSharing2,
  CaseSharing3,
  DamageResInfo,
  FAQ,
  Install,
  MainRanking,
  NewPhishing,
  Notices,
  PhishingDataDetail,
  ShareLanding,
  UserInfomation,
  SelectBank, MainPhishing,
  Guard
} from 'views'
import { GlobalStyles } from '../styles'

const App = () => {
  return (
    <>
      <GlobalStyles />
      <Routes>
        <Route path='install' element={<Install />} />
        <Route path='ShareLanding/:id' element={<ShareLanding />} />
        <Route path='MainRanking' element={<MainRanking />} />
        {/*최신피싱사례*/}
        <Route path='NewPhishing' element={<NewPhishing />} />
        <Route path='NewPhishing/:channel' element={<NewPhishing />} />
        {/*주요피싱유형*/}
        <Route path='MainPhishing' element={<MainPhishing />} />
        <Route path='MainPhishing/:channel' element={<MainPhishing />} />
        <Route path='Notices' element={<Notices />} />
        <Route path='FAQ' element={<FAQ />} />
        <Route path='UserInfomation' element={<UserInfomation />} />
        <Route path='CaseSharing/:PNumber' element={<CaseSharing />} />
        <Route path='CaseSharing2/:PNumber/:Type' element={<CaseSharing2 />} />
        <Route path='CaseSharing2/:PNumber/:RecvPNumber/:CallTime/:Type/:Idx' element={<CaseSharing2 />} />
        <Route path='CaseSharing3/:PNumber/:RecvPNumber/:CallTime/:Type/:Idx' element={<CaseSharing3 />} />
        {/*피해신고*/}
        <Route path='DamageResInfo' element={<DamageResInfo />} />
        <Route path='CardContents' element={<CardContents />} />
        <Route path='CardContents/:channel' element={<CardContents />} />
        <Route path='CardContents/:channel/:month' element={<CardContents />} />
        {/*최신피싱사례 상세 */}
        <Route path='PhishingDataDetail/:idx' element={<PhishingDataDetail />} />
        <Route path='PhishingDataDetail/:pCode/:idx' element={<PhishingDataDetail />} />
        <Route path='CardContentsDetail/:idx' element={<CardContentsDetail />} />
        <Route path='CardContentsDetail/:channel/:idx' element={<CardContentsDetail />} />
        <Route path='selectBank' element={<SelectBank />} />
        <Route path='guard/:guardData' element={<Guard />} />
      </Routes>
    </>
  )
}

export default App
