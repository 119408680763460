/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { contentBox } from './GuardRegistForm'
import { ReactComponent as ErrorIcon } from 'assets/svgs/icon_error.svg'
import { body07, body08, body12 } from '../../styles/components/textStyle'

const GuardFail = () => {
  return (
    <section css={css`
      ${contentBox};
      background: #f7f7f7;
      margin: 0 0 150px 0;
    `}
    >
      <div css={css`
        display: flex;
        align-items: center;
        gap: 10px;
      `}
      >
        <ErrorIcon />
        <p css={css`${body07}`}>등록불가</p>
      </div>
      <p css={css`${body08}`}>
        이미 등록되었거나,
        인증번호 유효시간 만료 또는 인증번호 3회 오류로
        등록이 불가합니다. <br />
        다시 기능을 요청 해주세요.
      </p>
      <p css={css`${body12}`}>
        [기능 요청 절차] <br />
        ① 스마트피싱보호 앱 실행 <br />
        ② 스마트설정 메뉴 이동<br />
        ③ 내가 보호받기 선택 후 ‘등록하기’ <br />
      </p>
    </section>
  )
}
export default GuardFail
