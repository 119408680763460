import axios, { AxiosResponse } from 'axios'
import { serverURL } from '../const'
import { Dispatch, SetStateAction } from 'react'

declare type AxiosCustomResponseType<R, T> = AxiosResponse<ResponseData<R>, RequestData<T>>

function requestData<B> (cmdType: string, body: B): RequestData<B> {
  return {
    Header: { CmdType: cmdType },
    Body: body
  }
}

const fetcher = async <R, T> (requestURL: RequestURL, data: RequestData<T>): Promise<AxiosCustomResponseType<R, T>> => {
  return await axios.post(requestURL, JSON.stringify(data))
}
const getServerURL = (): string => {
  const host = window.location.host
  return host.includes('localhost') || host.includes('dev-') || host.includes('5.0')
    ? serverURL.dev
    :  serverURL.live
}
const restServer = async <R, T> (requestData: RequestData<T>, callBackFn?: Dispatch<SetStateAction<number>>): Promise<R | undefined> => {
  try {
    const { data } = await fetcher<R, T>(getServerURL(), requestData)
    // @ts-expect-error
    const { Header, Body } = JSON.parse(data)
    switch (Header.ErrCode) {
      case 0:
        if (callBackFn != null) { callBackFn(1) }
        return Body
      default:
        alert(`${Header.ErrCode}: ${Header.ErrMsg}`)
        if (callBackFn != null) { callBackFn(2) }
    }
  } catch (e) {
    console.error(e)
  }
}
export {
  requestData,
  restServer
}
