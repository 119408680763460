/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { serverURL } from 'service/const'
import { description4 } from 'styles/components/textStyle'
import { colors } from 'styles'
import dayjs from 'dayjs'
import { useAppFunc } from 'hooks'
import { ReactComponent as NewIcon } from 'assets/svgs/new_icon.svg';

export interface ItemProps extends PhishingData {
  isNew?: boolean
}
const NewItem = ({ Idx, serviceImg, RegDT, isNew = false }: ItemProps) => {
  const { detail } = useAppFunc()
  return (
    <article
      css={css`
      margin: ${isNew ? '-9px 0 2px 0': '0 auto'};
      display: flex;
      flex-direction: column;
    `} onClick={() => detail(Idx)}
    >
      {isNew && <NewIcon css={css`position: relative; margin-top:-20px;top:20px; left:-10px;` } />}
      <div css={css`
        border-radius: 10px;
        width: 100%;
        height: 90px;
        background-image: url("${serverURL.img}/serviceimage/${serviceImg}");
        background-size: 100%;
        background-position-y: -10px;
      `}
      />
      <div css={css`${description4}; color: ${colors.back04}; display: flex; margin: 8px 0 10px 0;`}>
        {dayjs(RegDT).format('YYYY.MM.DD')}
      </div>
    </article>
  )
}
export default NewItem
