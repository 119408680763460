import React from 'react'
import { Global, css } from '@emotion/react'
import { colors } from './variables'

export const GlobalStyles = () => (
  <Global
    styles={css`
        html,
        body,
        #root {
          margin: auto;
          font-family: 'Noto Sans KR', sans-serif;
          color: ${colors.back01};
        }  
      `}
  />
)
