import AntiscamTab from '../components/common/tab/AntiscamTab'
import FAQTabItem from '../components/faq/FAQTabItem'
import { useAPI } from 'hooks'
import { decode } from 'js-base64'

const FAQ = () => {
  const { faq } = useAPI()
  const { data } = faq()
  const names = [
    '전체',
    '서비스 이용',
    '가입 및 해지',
    '기타'
  ]
  const decoded = data?.map<FAQ>((faq) => {
    return {
      Idx: faq.Idx,
      Title: decode(faq.Title),
      Contents: decode(faq.Contents),
      Group1: decode(faq.Group1),
      Group2: decode(faq.Group2),
      ContentsType: faq.ContentsType
    }
  })
  const tabs = [
    <FAQTabItem key={0} data={decoded ?? []} />,
    <FAQTabItem key={1} data={decoded?.filter((faq) => faq.Group1 === '서비스 이용') ?? []} />,
    <FAQTabItem key={2} data={decoded?.filter((faq) => faq.Group1 === '서비스 가입 및 해지') ?? []} />,
    <FAQTabItem key={3} data={decoded?.filter((faq) => faq.Group1 === '기타') ?? []} />
  ]
  console.log(data)
  return (<AntiscamTab names={names} children={tabs} />)
}
export default FAQ
