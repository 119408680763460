/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { PropsWithChildren } from 'react'

export const contentsStyle = css`
  margin: auto 20px;
  {...props}
`

const ContentsLayout = (props: PropsWithChildren) => {
  const { children } = props
  return (
    <div css={contentsStyle}>
      {children}
    </div>
  )
}
export default ContentsLayout
