/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { PropsWithChildren } from 'react'
import { colors } from '../../../styles'

const TabArticle = ({ children }: PropsWithChildren) => {
  return (
    <article css={css`
      border: 1px solid ${colors.back06};
      border-radius: 10px;
      margin: 10px auto;
      padding: 20px;
      display: flex;
    `}
    >
      {children}
    </article>
  )
}
export default TabArticle
