import { css } from '@emotion/react'
import { colors } from '../variables'
import { description3 } from './textStyle'

export const tabContentsFont = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 17.38px;
  color: ${colors.back01}
`
export const tabContentsH2 = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
`
export const tabContentsH3 = css`
  font-weight: 700;
  font-size: 12px;
  line-height: 17.38px;
 `
export const tabArticleTitle = css`
  font-weight: 700;
  font-size: 12px;
  line-height: 17.38px;
`
export const tabDescription = css`
  ${description3};
  color: ${colors.back03}
`
