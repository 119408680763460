/** @jsxImportSource @emotion/react */
import { ReactComponent as CheckImg } from 'assets/svgs/guard_check.svg'
import { css } from '@emotion/react'
import { body07 } from '../../styles/components/textStyle'

const topBanner = css`
  height: 113px;
  background: #18634d;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 20px;
`
const topBannerContainer = css`
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const topText = css`
  width: 100%;
  height: 23px;
  background: #3dc289;
  border-radius: 20px;
  display: flex;
  align-items: center;
`
const bannerText1 = css`
  color: #fff; 
  font-size: 12px;
  font-weight: 700;
  padding: 3.6px 12px;
  margin: 0;
`
const bannerText2 = css`
  color: #fff;
  font-size: .9em;
  font-weight: 400;
  margin: 0;
`
const GuardTopBanner = () => {
  return (
    <div css={topBanner}>
      <CheckImg />
      <div css={topBannerContainer}>
        <div css={topText}>
          <p css={bannerText1}>STOP.THINK.CONNECT</p><br />
        </div>
        <p css={bannerText2}>
          당신의 소중한 가족을 <br />
          <span css={css`${body07};
            color: #ffdf5a;`}
          >보이스피싱 피해
          </span>로부터 보호해주세요.
        </p>
      </div>
    </div>
  )
}
export default GuardTopBanner
