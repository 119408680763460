/** @jsxImportSource @emotion/react */
import { colors } from '../../styles'
import { body04, body10, body12, description2, description3, heading1 } from '../../styles/components/textStyle'
import { css } from '@emotion/react'
import { ReactComponent as CheckBox } from 'assets/svgs/checkbox.svg'
import CallBtn from '../common/btn/CallBtn'
import { FormEvent, KeyboardEvent, useRef, useState } from 'react'
import { useAPI } from '../../hooks'
import GuardRegistSuccess from './GuardRegistSuccess'
import GuardFail from './GuardFail'

export const contentBox = css`
  ${description2};
  padding: 16px;
  background: ${colors.main06};
  color: ${colors.main04};
  border-radius: 10px;
`
const articleBox = css`
  background: ${colors.back07};
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
const confirmBtn = css`
  background: #3dc289;
  width: 100%;
  height: 51px;
  border: none;
  border-radius: 100px;
  box-shadow: 0 4px 4px rgba(61, 194, 128, 0.16);
  color: #fff;
  ${body04}
`

interface GuardRegistFormProps {
  TransID: string
  resData: GuardData
  handleViewTerms: () => void
}

type MakeVerifyGuard = (transId: string, certNum: string) => VerifyGuard

const GuardRegistForm = ({ TransID, resData, handleViewTerms }: GuardRegistFormProps) => {
  const [stage, setStage] = useState<number>(0)
  const [certNumber, setCertNumber] = useState('')
  const certInput = useRef<HTMLInputElement>(null)
  const [termsCheck, setTermsCheck] = useState(false)
  const { RespPNumber, SenderPNumber } = resData
  const { verifyGuard } = useAPI()
  const { mutate } = verifyGuard()
  console.log('resp:', RespPNumber, 'Sender : ', SenderPNumber)
  const makeVerifyGuardBody: MakeVerifyGuard = (transId: string, certNum: string) => {
    return { TransID: transId, CertNumber: certNum }
  }
  const verifySubmit = () => {
    if (certNumber.length !== 4) {
      alert('인증번호를 입력해주세요.')
      return false
    }
    if (!termsCheck) {
      agreeTermsConfirm()
    }
    mutate({ body: makeVerifyGuardBody(TransID, certNumber), callbackFn: setStage })
  }
  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      verifySubmit()
    }
  }
  const agreeTermsConfirm = () => {
    window.confirm('알림 등록을 위해서 개인정보 수집 및 이용에 동의하셔야 합니다.\n동의하시겠습니까?')
      ? handleTerms()
      : handleTerms()
  }
  const handleTerms = () => {
    setTermsCheck(!termsCheck)
  }
  const handleInput = (event: FormEvent<HTMLInputElement>) => {
    const regexp: RegExp = /[^0-9]/g
    const value = event.currentTarget.value.replace(regexp, '').slice(0, 4)
    setCertNumber(value)
  }
  setTimeout(() => {
    if (certInput.current != null) {
      certInput.current.focus()
    }
  }, 100)

  return (
    <>
      {
        stage === 0
          ? <section>
            <article css={contentBox}>
              <p css={css`${body12};
                margin: 0;`}
              >
                보호받는 가족이 피싱 의심 전화를 수신하는 경우, 고객님에게 알림을 보냅니다. <br />
                * 통화 시간: 2, 5, 7분(총 3번) <br />
                * 단말 기종에 따라 알림 시간 오차 발생
              </p>
            </article>
            <p css={css`${description3};
              color: ${colors.back03};`}
            >
              ※ 본 기능은 고객님께서 스마트피싱보호 가입자가 아니더라도,
              <span css={css`color: ${colors.red00}`}>무료</span>로 SMS를 받을 수 있습니다.
            </p>
            <article css={articleBox}>
              <h3 css={css`${body04};
                margin: 0;`}
              >보호받는 번호
              </h3>
              <p css={css`font-weight: 400;
                font-size: 12px;
                color: ${colors.back03};`}
              >가족이 요청한 내용이 맞는지 전화로 확인해보세요.
              </p>
              <div css={css`
                display: flex;
                align-items: center;
                gap: 10px;
              `}
              >
                <p css={css`
                  ${heading1};
                  margin: auto 0;
                `}
                >
                  {SenderPNumber.slice(0, 3)}-{SenderPNumber.slice(3, 7)}-{SenderPNumber.slice(7, 11)}
                </p>
                <CallBtn tel={SenderPNumber} />
              </div>
            </article>
            <article css={articleBox}>
              <h3 css={css`${body10};`}>인증번호</h3>
              <input
                css={css`
                padding: 18px 0 18px 12px;
                border: 1px solid ${colors.back05};
                border-radius: 16px;
                font-size: 14px;
                width: 95%;
              `}
                ref={certInput}
                value={certNumber}
                placeholder='4자리 숫자로 입력'
                onChange={handleInput}
                onKeyUp={handleKeyUp}
              />
              <p css={css`font-weight: 400;
                font-size: 12px;
                color: ${colors.back03};`}
              >
                가족피싱알림 등록을 원하시면, 가족에게 전송된 인증번호를 입력 후 승인해주세요.
              </p>
            </article>
            <article css={css`margin: 21px 0 32px;`}>
              <label css={css`
                display: flex;
                align-items: center;
              `}
              >
                <input type='checkbox' hidden onChange={handleTerms} />
                <div css={css`margin: auto 0;
                  width: 34px;
                  display: flex;
                  align-items: center;`}
                >
                  <CheckBox fill={termsCheck ? colors.main01 : '#f1f1f1'} />
                </div>
                <p css={css`${body12}`}>개인정보 수집 및 이용 동의</p>
                <button
                  css={css`
                  margin: 0 0 0 auto;
                  color: ${colors.main01};
                  font-size: 12px;
                  font-weight: 400;
                  text-decoration-line: underline;
                  border: none;
                  background: none;
                `} onClick={handleViewTerms}
                >약관보기
                </button>
              </label>
            </article>
            <article>
              <button css={confirmBtn} onClick={verifySubmit}>확인</button>
              <p css={css`
                font-size: 10px;
                font-weight: 400;
                text-align: center;
                margin: 30px 0 60px;
                color: ${colors.back03};
              `}
              >
                ※ 인증번호는 개인정보보호로 인하여 24시간 동안 유효합니다.
              </p>
            </article>
          </section>
          : stage === 1
            ? <GuardRegistSuccess />
            : <GuardFail />
      }

    </>
  )
}
export default GuardRegistForm
