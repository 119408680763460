/** @jsxImportSource @emotion/react */
import { contentBox } from './GuardRegistForm'
import { css } from '@emotion/react'

const GuardRegistSuccess = () => {
  return (
    <section css={css`${contentBox}; margin: 0 0 150px 0;`}>
      <p css={css`
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        padding: 24px 0;
      `}
      >가족피싱알림 서비스가 등록되었습니다.
      </p>
    </section>
  )
}
export default GuardRegistSuccess
