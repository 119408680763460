/** @jsxImportSource @emotion/react */
import { ReactComponent as FooterLogo } from 'assets/svgs/footer_logo.svg'
import { css } from '@emotion/react'

const footer = css`
  width: 100%;
  height: 170px;
  background: #1c2339;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`
const GuardFooter = () => {
  return (
    <section css={footer}>
      <article>
        <FooterLogo />
      </article>
      <article>
        <p css={css`color: #fff;
          font-size: 10px;`}
        >
          (주)데이터유니버스 <br />
          대표 : 강원석 | 서울특별시 영등포구 국제금융로8길 32 여의도파이낸스 타워 <br />
          고객지원센터 :1811-3751 (공휴일 휴무 09:00 ~ 12:00 / 13:00 ~ 18:00) <br />
          Copyright © 2018 DataUniverse. All Rights Reserved.
        </p>
      </article>
    </section>
  )
}
export default GuardFooter
