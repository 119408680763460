/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { heading3 } from '../styles/components/textStyle'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAPI } from '../hooks'
import GuardTopBar from '../components/guard/GuardTopBar'
import GuardTopBanner from '../components/guard/GuardTopBanner'
import GuardFooter from '../components/guard/GuardFooter'
import GuardRegistForm from '../components/guard/GuardRegistForm'
import GuardFail from '../components/guard/GuardFail'
import GuardTerms from '../components/guard/GuardTerms'

const Guard = () => {
  const [viewTerms, setViewTerms] = useState(false)
  const { guardData } = useParams()
  const { getGuardData } = useAPI()
  const { data } = getGuardData(guardData)
  const handleViewTerms = () => {
    setViewTerms(!viewTerms)
  }
  return (
    <div>
      <GuardTopBar />
      <GuardTopBanner />
      {
        !viewTerms
          ? <section css={css`padding: 0 20px;`}>
            <h3 css={heading3}>가족피싱알림</h3>
            {
              guardData
                ? (data != null)
                    ? <GuardRegistForm
                        TransID={guardData}
                        resData={data}
                        handleViewTerms={handleViewTerms}
                      />
                    : <GuardFail />
                : <GuardFail />
            }
          </section>
          : <GuardTerms handleViewTerms={handleViewTerms} />
      }
      <GuardFooter />
    </div>
  )
}
export default Guard
