const useScroll = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0)
  }

  return {
    scrollTop
  }
}
export default useScroll
