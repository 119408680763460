import { css } from '@emotion/react'
import { colors } from '../variables'

export const buttonType6 = css`
  width: fit-content;
  margin: auto 0 auto auto;
  background: ${colors.main06};
  color: ${colors.main02};
  border: none;
  border-radius: 100px;
  padding: 8px 16px;
  &:hover {
    background: ${colors.main05};
  }
  &:disabled {
    opacity: 0.3;
  }
`
export const buttonCall = css`
  background: none;
  border: none;
  &:disabled {
    opacity: 0.3;
  }
`
