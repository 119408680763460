/** @jsxImportSource @emotion/react */
import DataDetail from '../components/phishingData/DataDetail'
import { useAPI } from 'hooks'
import { useParams } from 'react-router-dom'

const PhishingDataDetail = () => {
  const { pCode, idx } = useParams()
  const { phishingDetail } = useAPI()
  const { data } = phishingDetail(pCode || 'antiscamshare', idx)
  console.log('datadetail', data)
  return (
    <section>
      {
        (data != null)
          ? <DataDetail
              IconText={data.IconText}
              CardThumbNail={data.CardThumbNail}
              CardTitle={data.CardTitle}
              images={data.images}
              Idx={data.Idx}
              Title={data.Title}
              IconName={data.IconName}
              Contents={data.Contents}
              ContentsGenre={data.ContentsGenre}
              ContentsText={data.ContentsText}
              RegDT_Text={data.RegDT_Text}
              Url={data.Url}
              RegDT={data.RegDT}
              serviceImg={data.serviceImg}
              CardThumbNailAlt={data.CardThumbNailAlt}
              altImages={data.altImages}
              pCode={pCode}
            />
          : <div />
      }
    </section>
  )
}
export default PhishingDataDetail