/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ContentsLayout } from '../../layout'
import NewItem from './NewItem'
import MainItem from './MainItem'
type ListType = 'new' | 'main'
interface DataListProps {
  type: ListType
  data: PhishingData[]
}
const DataList = ({ type, data }: DataListProps) => {
  return (
    <ContentsLayout>
      {
        type === 'new'
          ? <section css={css`margin: 20px auto;`}>
            {
            data.map((item, index) =>
              <NewItem
                key={index}
                Idx={item.Idx}
                Title={item.Title}
                CardTitle={item.CardTitle}
                IconName={item.IconName}
                IconText={item.IconText}
                Contents={item.Contents}
                ContentsGenre={item.ContentsGenre}
                ContentsText={item.ContentsText}
                RegDT_Text={item.RegDT_Text}
                Url={item.Url}
                RegDT={item.RegDT}
                serviceImg={item.serviceImg}
                CardThumbNail={item.CardThumbNail}
                CardThumbNailAlt={item.CardThumbNailAlt}
                images={item.images} altImages={item.altImages}
                isNew={index < 2}
              />
            )
          }
            </section>
          : <section css={css`
            margin: 0 auto 20px auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
          `}
            >
            {
              data.map((main, index) =>
                <MainItem
                  key={index}
                  Idx={main.Idx}
                  Title={main.Title}
                  CardTitle={main.CardTitle}
                  IconName={main.IconName}
                  IconText={main.IconText}
                  Contents={main.Contents}
                  ContentsGenre={main.ContentsGenre}
                  ContentsText={main.ContentsText}
                  RegDT_Text={main.RegDT_Text}
                  Url={main.Url}
                  RegDT={main.RegDT}
                  serviceImg={main.serviceImg}
                  CardThumbNail={main.CardThumbNail}
                  CardThumbNailAlt={main.CardThumbNailAlt}
                  images={main.images}
                  altImages={main.altImages}
                  isNew={index < 2}
                />
              )
            }

          </section>
      }
    </ContentsLayout>

  )
}
export default DataList
