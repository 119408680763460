export const colors = {
  main01: '#3dc289',
  main02: '#2e936f',
  main03: '#18634d',
  main04: '#272e40',
  main05: '#b2ebd2',
  main06: '#d9f4e9',
  back01: '#2a292e',
  back02: '#3e3e40',
  back03: '#757578',
  back04: '#afaeb3',
  back05: '#e0e0e0',
  back06: '#f1f1f1',
  back07: '#f7f7f7',
  white00: '#fffff',
  red00: '#e7321a'
}
export const resolutions = {
  default: '360px',
  contentType1: '320px',
  contentType2: '300px'
}
