/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ReactComponent as HeaderLogo } from 'assets/svgs/guard_logo.svg'

const topBar = css`
  background: #fff;
  padding: 15px 20px;
  display: flex;
  gap: 14px;
`
const divider = css`
  width: 1.5px;
  background: #e0e0e0;
`
const topTitle = css`
  margin: 0 auto 0 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 17.38px;
`
const GuardTopBar = () => {
  return (
    <div css={topBar}>
      <HeaderLogo />
      <div css={divider} />
      <div css={topTitle}>보이스피싱 예방 서비스</div>
    </div>
  )
}
export default GuardTopBar
