/** @jsxImportSource @emotion/react */
import { body04, body12 } from '../../styles/components/textStyle'
import { css } from '@emotion/react'
import { tabDescription } from '../../styles/components/antiscamTabStyle'
import { useScroll } from '../../hooks'

const DamageTabItem3 = () => {
  const { scrollTop } = useScroll()
  scrollTop()
  return (
    <section css={body12}>
      <section>
        <h2 css={body04}>은행 방문하여 피해구제(환급)신청</h2>
        <p>
          경찰서 방문시 발급받은 사건사고사실 확인원, 신분증을 은행에 제출합니다.
        </p>
        <p>
          이후 아래와 같은 환급절차를 거치게 되며 피해금을 환급처리 됩니다.
        </p>
      </section>
      <section>
        <h2 css={body04}>환급 절차</h2>
        <p>
          <span css={css`text-decoration: underline;`}>1. 채권소멸절차공고 발행<br /></span>
          지급정지 요청을 받은 금융회사(은행)는 지급정지 후 금감원에 채권소멸절차공고를 요청합니다.
        </p>
        <p>
          <span css={css`text-decoration: underline;`}>2. 채권소멸 및 환불처리 <br /></span>
          2개월 동안 별다른 이의가 제기되지 않으면 해당계좌의 채권이 소멸하게 됩니다. 이후 채권소멸일로 부터 14일 이내 환급금액이 결정되고, 환불처리가 진행됩니다.
        </p>
        <img css={css`width: 100%;`} src='/images/img_report_content3.png' alt='환급 절차' />
        <p css={tabDescription}>출처 : 금융감독원</p>
      </section>
    </section>
  )
}
export default DamageTabItem3
