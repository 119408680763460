import AntiscamTab from '../components/common/tab/AntiscamTab'
import DamageTabItem1 from '../components/damageResInfo/DamageTabItem1'
import DamageTabItem2 from '../components/damageResInfo/DamageTabItem2'
import DamageTabItem3 from '../components/damageResInfo/DamageTabItem3'
import DamageTabItem4 from '../components/damageResInfo/DamageTabItem4'

const DamageResInfo = () => {
  const names = [
    '1.지급정지',
    '2.신고',
    '3.환급',
    '4.청구'
  ]
  const contents = [
    <DamageTabItem1 />,
    <DamageTabItem2 />,
    <DamageTabItem3 />,
    <DamageTabItem4 />
  ]
  return (
    <AntiscamTab names={names} children={contents} />
  )
}
export default DamageResInfo
