/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { body12, heading3 } from '../../styles/components/textStyle'
import { colors } from '../../styles'
import { useScroll } from '../../hooks'

interface GuardTermsProps {
  handleViewTerms: () => void
}
const GuardTerms = ({ handleViewTerms }: GuardTermsProps) => {
  const { scrollTop } = useScroll()
  scrollTop()
  return (
    <section>
      <section css={css`padding: 0 20px; margin: 0 0 150px 0;`}>
        <h3 css={heading3}>개인정보 수집 및 이용안내</h3>
        <article
          css={css`${body12};
          color: ${colors.main04};`} onClick={handleViewTerms}
        >
          <p>
            1. 수집 정보<br />
            ㈜데이터유니버스는 ‘스마트피싱보호’ 서비스의 원활한 이용을 위하여 아래와 같은 정보를 수집합니다.<br />
            * 수집 정보 : 휴대폰번호, 이통사정보
          </p>
          <p>
            2. 수집 및 이용 목적<br />
            수집된 정보는 스마트피싱보호 서비스의 가족보호알림 수신을 위한 목적으로 활용합니다.
          </p>
          <p>
            3. 보관 및 이용 기간<br />
            이용자의 정보는 가족보호알림 신청자가 수신자정보를 삭제 시 즉시 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 아래와 같이 관계법령에서 정한 일정 기간동안 개인정보를 보관할
            수 있습니다.<br />
            - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)
          </p>
        </article>
      </section>
    </section>
  )
}
export default GuardTerms
