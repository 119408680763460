
export const serverURL: URLs = {
  live: 'https://swp.antiscam.co.kr:10011',
  dev: 'https://dev-swp.antiscam.co.kr:10011',
  svc: 'https://svcapi.antiscam.co.kr:10031',
  img: 'https://images.antiscam.co.kr',
  partner: 'https://partner.antiscam.co.kr',
  webappLive: 'https://webapp.antiscam.co.kr',
  webappDev: 'https://dev-webapp.antiscam.co.kr'
}

export const banks: Bank[] = [
  { name: 'IBK 기업은행', tel: ['1588-2588'], imgPath: '/images/ico_bank_ibk.png' },
  { name: '국민은행', tel: ['1588-9999', '1599-9999'], imgPath: '/images/ico_bank_kb.png' },
  { name: 'KDB 산업은행', tel: ['1588-1500'], imgPath: '/images/ico_bank_kdb.png' },
  { name: 'NH농협은행', tel: ['1661-3000', '1522-3000'], imgPath: '/images/ico_bank_nh.png' },
  { name: 'SC제일은행', tel: ['1588-1599'], imgPath: '/images/ico_bank_sc.png' },
  { name: '수협은행', tel: ['1588-1515', '1644-1515'], imgPath: '/images/ico_bank_suhyup.png' },
  { name: '신한은행', tel: ['1577-8000', '1544-8000'], imgPath: '/images/ico_bank_shinhan.png' },
  { name: '신협', tel: ['1566-6000'], imgPath: '/images/ico_bank_sinhyup.png' },
  { name: '우리은행', tel: ['1588-5000', '1599-5000'], imgPath: '/images/ico_bank_woori.png' },
  { name: '하나은행', tel: ['1599-1111'], imgPath: '/images/ico_bank_hana.png' },
  { name: '한국씨티은행', tel: ['1588-7000'], imgPath: '/images/ico_bank_citi.png' },
  { name: '카카오뱅크', tel: ['1599-3333'], imgPath: '/images/ico_bank_kakao.png' },
  { name: '케이뱅크', tel: ['1522-1000'], imgPath: '/images/ico_bank_kbank.png' },
  { name: '토스뱅크', tel: ['1661-7654'], imgPath: '/images/ico_bank_toss.png' },
  { name: '경남은행', tel: ['1600-8585', '1588-8585'], imgPath: '/images/ico_bank_bnk.png' },
  { name: '부산은행', tel: ['1588-6200'], imgPath: '/images/ico_bank_bnk.png' },
  { name: '대구은행', tel: ['1566-5050', '1588-5050'], imgPath: '/images/ico_bank_dg.png' },
  { name: '광주은행', tel: ['1588-3388', '1600-4000'], imgPath: '/images/ico_bank_kj.png' },
  { name: '새마을금고', tel: ['1599-9000'], imgPath: '/images/ico_bank_kfcc.png' },
  { name: '우체국금융', tel: ['1588-1900'], imgPath: '/images/ico_bank_post.png' },
  { name: '전북은행', tel: ['1588-4477'], imgPath: '/images/ico_bank_kj.png' },
  { name: '제주은행', tel: ['1588-0079'], imgPath: '/images/ico_bank_shinhan.png' },
  { name: '지역 농·축협', tel: ['1588-2100', '1544-2100'], imgPath: '/images/ico_bank_nh.png' }
]
export const kakaoKey: string = 'bc951a3379957948f164a0928420bea8'