/** @jsxImportSource @emotion/react */
import {body04, body12, description2, description3} from '../../styles/components/textStyle'
import TabArticle from '../common/tab/TabArticle'
import {css} from '@emotion/react'
import CallBtn from '../common/btn/CallBtn'
import {useScroll} from '../../hooks'

const DamageTabItem4 = () => {
  const {scrollTop} = useScroll()
  scrollTop()
  return (
    <section css={body12}>
      <TabArticle>
        <div css={css`margin: auto auto auto 0;`}>
          <div css={css`${description2};
            margin-bottom: 3px;
            font-weight: 700;`}>
            데이터 유니버스 고객센터
          </div>
          <div css={css`${description3};
            color: #808080;`}>09:00~18:00 주말 및 공휴일 휴무
          </div>
        </div>
        <CallBtn tel='1811-7531'/>
      </TabArticle>
      <section>
        <h2 css={css`${body04};
          margin: 30px auto 20px auto;`}>보험금 보상 범위</h2>
        <p>
          대한민국 내에서 스마트피싱보호 서비스 이용 중에 발생한 금융사기*로 인해 스마트피싱보호 서비스 이용자 명의의 계좌에서
          예금이 부당 인출(부당 이체 포함)되거나 신용카드(직불, 현금 카드 등 포함)가 부당하게 사용되어 피보험자가 입은 금전적
          손해를 약관에 따라 보상하여 드립니다.
        </p>
        <ul css={css`
          padding: 0 10px;

          li {
            list-style-type: "*";

            ul {
              padding: 0 15px;

              li {
                list-style-type: disc;
              }
            }
          }
        `}
        >
          <li>
            금융사기 피싱(Phishing)
            <ul>
              <li>해킹(Hacking)</li>
              <li>스미싱</li>
              <li>파밍</li>
              <li>메모리해킹</li>
            </ul>
          </li>
        </ul>
      </section>
      <section>
        <h2 css={body04}>보상 내용</h2>
        <ul css={css`
          padding: 0 0 0 20px;

          li {
            ul {
              padding: 0;

              li {
                list-style-type: none;
              }
            }
          }
        `}
        >
          <li>
            보상한도액: 1인 1사고당 300만원
            <ul>
              (자기 부담금 60만원이 발생/다른 보험계약에 따른 보험금, 금융기관 등이 제공하는 보상제도 및 가해자에 의해 보상을 받을 수 있는 경우, 그 금액을 공제함)
            </ul>
          </li>
          <li>
            보험기간: 스마트피싱보호 서비스 신규 가입 후 해지시까지
          </li>
        </ul>
      </section>
      <section>
        <h2 css={body04}>보험금 청구시 필요구비서류</h2>
        <ul css={css`padding: 0 0 0 20px;`}>
          <li>보상시 구비서류</li>
          <li>보험금 청구서</li>
          <li>개인정보제공동의서</li>
          <li>신분증 사본</li>
          <li>경찰서 사건사실확인원</li>
          <li>피해자 진술조서</li>
          <li>사고금액의 은행송금중/통장기장내역/거래내역서 중 택1</li>
          <li>사기범 대포통장에 대한 지급정지사실통지서</li>
          <li>보험금 지급받으실 통장사본(본인명의)</li>
        </ul>

        *구비서류는 사고사항에 따라 추가요청 될 수 있습니다.
      </section>
    </section>
  )
}
export default DamageTabItem4
