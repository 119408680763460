/** @jsxImportSource @emotion/react */
// import {useAPI} from "hooks";
import DataList from '../components/phishingData/DataList'
// import {useState} from "react";
// import {useParams} from "react-router-dom";

const CardContents = () => {
  // let { channel, month } = useParams()
  // const [offset, setOffset] = useState(0)
  return (
    <>
      <DataList type='new' data={[]} />
    </>
  )
}
export default CardContents
