/** @jsxImportSource @emotion/react */
import { PropsWithChildren, ReactNode, useState } from 'react'
import { css } from '@emotion/react'
import { colors } from 'styles'
import { ContentsLayout } from 'layout'

interface AntiscamTabProps extends PropsWithChildren {
  names: string[]
  children: ReactNode[]
}
const AntiscamTab = ({ names, children }: AntiscamTabProps) => {
  const tabContainer = css`
    width: 100%;
    margin: 0 0 100px 0;
  `
  const tabTitles = css`
    position: fixed;
    height: 46px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    background: #fff;
    border-bottom: 1px solid ${colors.back06};
  `
  const tabTitle = css`
    width: 22.25%;
    font-size: 12px;
    color: ${colors.back01};
    font-weight: 500;
    font-family: 'Noto Sans KR', sans-serif;
    background: none;
    border: none;
    line-height: 17px;
    letter-spacing: -0.04em;
    text-align: center;
  {...props}
  `
  const tabActive = css`
    color: ${colors.main01};
    border-bottom: 2px solid ${colors.main01};
    margin:0;
  `
  const tabDivider = css`
    width: 100%;
    height: 1px;
    margin: 0 auto 65px;
  `
  const tabContents = css``
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <div css={tabContainer}>
      <div css={tabTitles}>
        {names.map((name, index) =>
          <button
            css={css`
              ${tabTitle};
              ${index === tabIndex ? tabActive : undefined};
            `} key={index} onClick={() => setTabIndex(index)}
          >{name}
          </button>
        )}
      </div>
      <div css={tabDivider} />
      <div css={tabContents}>
        <ContentsLayout>
          {children[tabIndex]}
        </ContentsLayout>
      </div>
    </div>
  )
}
export default AntiscamTab
