/** @jsxImportSource @emotion/react */
import { useAPI } from 'hooks'
import { css } from '@emotion/react'
import { body10, body12 } from '../styles/components/textStyle'
import SomeRank from '../assets/svgs/icon_some_rank.svg'
import HotWord from '../assets/svgs/icon_hot_word.svg'
import NewWord from '../assets/svgs/icon_new_word.svg'
import upArrow from '../assets/svgs/icon_up_arrow.svg'
import downArrow from '../assets/svgs/icon_down_arrow.svg'

const MainRanking = () => {
  const { mainRank } = useAPI()
  const { data } = mainRank()
  const arrowImg = (iconNum: number) => {
    switch (iconNum) {
      case 0:
        return SomeRank
      case 1:
        return HotWord
      case 2:
        return NewWord
      case 3:
        return upArrow
      default:
        return downArrow
    }
  }
  const ItemRow = ({ index, title, iconNum }: { index: number, title: string, iconNum: number }) => {
    return (
      <article
        key={index} css={css`display: grid;
        width: 100%;
        grid-template-columns: 15% 50% 30%;
        gap: 5px;`}
      >
        <div css={body10}>{index + 1}</div>
        <div css={body12}>{title}</div>
        <img
          css={css`margin: auto;
          width: 1.7em;`} src={arrowImg(iconNum)} alt='left icon'
        />
      </article>
    )
  }
  return (
    (data != null)
      ? <section css={css`width: 100%; margin: 14px auto;`}>
        <section css={css`display: grid;
          grid-template-columns: 48% 48%; 
          gap: 14px 9px;`}
        >
          {data.map((data, index) =>
            <ItemRow index={index} title={data.Title} iconNum={data.IconNum} />
          )}
        </section>
        </section>
      : <div />

  )
}
export default MainRanking
