/** @jsxImportSource @emotion/react */
import { body04, body11, body12 } from '../../styles/components/textStyle'
import { css } from '@emotion/react'
import { tabDescription } from '../../styles/components/antiscamTabStyle'
import { useScroll } from '../../hooks'

const DamageTabItem2 = () => {
  const { scrollTop } = useScroll()
  scrollTop()
  return (
    <section css={body11}>
      <section css={body12}>
        <h2 css={body04}>경찰서 방문 피해 신고</h2>
        <p>계좌지급정지 완료 이후 범죄 신고와 <span css={css`text-decoration: underline;`}>피해금 환급용 서류(은행 제출) 발급을 위해 방문신고</span>가 필요합니다.</p>
        <p>경찰서 방문시 사건사고사실 확인원을 발급받습니다. 이 때, 발급받은 서류를 은행에 제출합니다.</p>
      </section>
      <section>
        <h2 css={body04}>피해구제신청</h2>
        <img css={css`width: 100%;`} src='/images/img_report_content2.png' alt='피해구제신청' />
        <p css={tabDescription}>출처 : 금융감독원</p>
      </section>
    </section>
  )
}
export default DamageTabItem2
