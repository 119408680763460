/** @jsxImportSource @emotion/react */
import { body08, description2 } from '../../styles/components/textStyle'
import { css } from '@emotion/react'
import { colors } from 'styles'
import {useEffect, useState} from 'react'
import { useScroll } from '../../hooks'


interface FAQTabItemProps {
  data: FAQ[]
}
const FAQTabItem = ({ data }: FAQTabItemProps) => {
  const { scrollTop } = useScroll()
  const [active, setActive] = useState(-1)
  const handleOpen = (index: number) => {
    if (active === index) setActive(-1)
    else setActive(index)
  }
  useEffect(()=>{
    scrollTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <section css={css`
      display: grid;
    `}
    >
      {data
        ? data.map((faq) =>
          <div
            key={faq.Idx} css={css`
            display: grid;
          `}
          >
            <div
              css={css`
              ${body08}; 
              margin: 16px 0; 
              ${active === faq.Idx
? ''
              : 'overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'}
            `} onClick={() => handleOpen(faq.Idx)}
            >Q. {faq.Title}
            </div>
            <div css={css`
              ${description2};
              padding: 16px;
              background: ${colors.main06};
              color: ${colors.main03};
              border-radius: 10px;
              ${active === faq.Idx ? 'display: box;' : 'display: none;'};
            `}
            >{faq.Contents}
            </div>
          </div>)
        : <div />}
    </section>
  )
}
export default FAQTabItem
