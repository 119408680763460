const useAppFunc = () => {
  const requestApp = (func: string) => {
    return window.location.href = `antiscam://${func}`
  }
  const detail = (index: string) => window.location.href = requestApp(`detail?${index}`)
  const share = (param: string) => requestApp(`share?${param}`)
  const bank = () => requestApp('bank')
  const notice = (index: string) => window.location.href = requestApp(`notice?${index}`)
  return {
    detail,
    share,
    bank,
    notice
  }
}
export default useAppFunc