import { MutationKey, useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import { ReactQueryKeys } from './hooks'
import { requestData, restServer } from '../service/api'
import { Dispatch, SetStateAction } from 'react'

export const queryKey: ReactQueryKeys = {
  mainRanking: ['mainRanking'],
  newPhishingData: ['newPhishingData'],
  mainPhishingData: ['mainPhishingData'],
  phishingDataDetail: ['phishingDataDetail'],
  faqData: ['faqData'],
  noticeData: ['noticeData'],
  guardData: ['guardData'],
  verifyGuard: ['verifyGuard']
}
export const cmdType: CmdType = {
  mainRanking: 'MainRanking',
  newPhishing: 'NewPhishingData',
  mainPhishing: 'MainPhishingData',
  phishingDataDetail: 'PhishingDataDetail',
  faqData: 'FAQ',
  noticeData: 'Notice',
  guardData: 'CALLGUARD',
  verifyGuard: 'SVC1020'
}

interface MutationKeys {
  verifyGuard: MutationKey
}

const mutationKeys: MutationKeys = {
  verifyGuard: ['verifyGuard']
}
/* 작성자: 이형태 사원
* 호출하면 서버에서 메인 랭킹 데이터를 가져오는 함수.
* */
const useGetMainRanking = () => useQuery(
  queryKey.mainRanking,
  async () => await restServer<MainRanking[], Length>(
    requestData<Length>(cmdType.mainRanking, { Length: 10 })
  )
)
/* 작성자: 이형태 사원
* 제휴사코드(pcode)와 페이지 시작 정보(Offset)을 넣으면 제휴사에 맞는 최신피싱사례를 가져오는 함수 */
const useGetNewPhishingData = (pCode: string = 'antiscamshare') => useInfiniteQuery(
  queryKey.newPhishingData,
  async ({ pageParam = 0 }) => await restServer<PhishingData[], ReqPhishingData>(
    requestData<ReqPhishingData>(cmdType.newPhishing, {
      PCode: pCode,
      Length: 10,
      Offset: pageParam
    })
  ),
  {
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage != null) {
        return lastPage.length < 10 ? undefined : allPages.length * 10
      }
    }
  }
)
/* 작성자 이형태 사원
* 제휴사코드와 페이지 시작 정보를 넣으면 제휴사에 맞는 주요피싱사례 데이터를 가져오는 함수 */
const useGetMainPhishingData = (pCode: string = '1db0fb43-1018') => useInfiniteQuery(
  queryKey.mainPhishingData,
  async ({ pageParam = 0 }) => await restServer<PhishingData[], ReqPhishingData>(
    requestData<ReqPhishingData>(cmdType.mainPhishing, {
      PCode: pCode,
      Length: 10,
      Offset: pageParam
    })
  ),
  {
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage != null) {
        return lastPage.length < 10 ? undefined : allPages.length * 10
      }
    }
  }
)
/* 작성자 이형태 사원
* 제휴사코드와 데이터 인덱스를 전달하면 상세 피싱데이터를 가져오는 함수 */
const useGetPhishingDataDetail = (pCode: string = 'antiscamshare', index: string = '0') => useQuery(
  queryKey.phishingDataDetail,
  async () => await restServer<PhishingDataDetail, ReqPhishingDataDetail>(
    requestData<ReqPhishingDataDetail>(cmdType.phishingDataDetail, { PCode: pCode, Idx: index })
  )
)
/* 작성자 이형태 사원
* 자주묻는 질문을 가져오는 함수 */
const useGetFAQData = () => useQuery(
  queryKey.faqData,
  async () => await restServer<FAQ[], ReqFAQ>(
    requestData<ReqFAQ>(cmdType.faqData, { Length: 100, Offset: 0 })
  )
)
/* 작성자 이형태 사원
* 공지사항 데이터를 가져오는 함수 */
const useGetNoticeData = () => useQuery(
  queryKey.noticeData,
  async () => await restServer<Notice[], ReqNotice>(
    requestData<ReqNotice>(cmdType.noticeData, { Length: 100, Offset: 0 })
  )
)
/* 작성자 이형태 사원
* 전달된 transId를 전달하면 가드 데이터를 가져오는 함수 */
const useGetGuardData = (params: string = 'test') => useQuery(
  queryKey.guardData,
  async () => await restServer<GuardData, ReqCallGuard>(
    requestData<ReqCallGuard>(cmdType.guardData, { TransID: params })
  )
)

interface UseVerifyGuardProps {
  body: VerifyGuard
  callbackFn: Dispatch<SetStateAction<number>>
}

const useVerifyGuard = () => useMutation(
  mutationKeys.verifyGuard,
  async ({
    body,
    callbackFn
  }: UseVerifyGuardProps) => await restServer<any, VerifyGuard>(requestData<VerifyGuard>(cmdType.verifyGuard, body), callbackFn)
)
const useAPI = () => {
  return {
    mainRank: useGetMainRanking,
    newPhishing: useGetNewPhishingData,
    mainPhishing: useGetMainPhishingData,
    phishingDetail: useGetPhishingDataDetail,
    faq: useGetFAQData,
    notice: useGetNoticeData,
    getGuardData: useGetGuardData,
    verifyGuard: useVerifyGuard
  }
}
export default useAPI
